import { Link } from "gatsby";
import React from "react";

const FullLogoAlt = ({ to }) => (
  <Link to={to}>
    <svg
      width="259"
      height="49"
      viewBox="0 0 370 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.7799 32.743C35.4708 34.1027 35.8595 35.923 36.0106 37.8749C37.8242 36.2958 42.2504 35.4624 45.7697 36.888C42.0776 36.888 37.5004 38.9056 36.0106 41.011C35.7299 45.156 34.3913 49.2571 32.0811 50.7923C42.0776 52.3933 46.1151 38.8398 54.5356 39.9145C51.2106 31.5367 39.0981 29.7603 34.7799 32.743Z"
        fill="#66A250"
      />
      <path
        d="M26.9206 12.0612C15.1104 13.5525 5.32975 35.5933 14.0741 64.9373C14.7434 67.1742 16.4059 66.933 19.3854 66.604C36.0536 64.8276 67.9865 61.3405 69.9297 39.212C71.1604 25.2418 60.2786 15.2631 46.9354 14.3859C46.6331 14.364 46.374 14.1446 46.2229 13.8595C44.1934 9.86806 36.8309 0.262207 26.7263 0.262207C-2.91797 0.262207 -3.76001 50.375 4.48771 63.8407C6.4093 66.9769 9.21611 66.604 8.50362 64.5644C1.03317 43.1815 7.48884 6.90735 27.4388 6.90735C33.8297 6.90735 38.2559 10.9427 42.574 19.2985C42.7468 19.6494 43.1138 19.781 43.4809 19.8248C57.9468 21.7548 63.7115 31.3387 62.8263 39.3216C61.8331 48.2696 55.2695 57.8754 18.8241 61.9766C17.0752 58.4018 13.0377 35.9442 19.5366 25.3076C16.557 38.2689 19.5366 49.8486 21.9979 53.9497C29.5331 50.8574 32.3616 47.3485 32.3616 39.5629C32.3616 28.1148 21.9763 21.5574 26.9206 12.0612Z"
        fill="#66A250"
      />
      <path
        d="M86.3604 48.094V46.0983C86.3604 45.6816 86.5547 45.4842 86.9649 45.4842H89.0808V24.4084H87.0081C86.5979 24.4084 86.4035 24.211 86.4035 23.7943V21.7328C86.4035 21.2941 86.5979 21.0748 87.0081 21.0748C90.2035 20.9432 93.2478 20.8774 96.1194 20.8774C98.991 20.8774 101.301 21.4477 103.007 22.5661C104.734 23.6846 105.576 25.2856 105.576 27.3691C105.576 28.8823 105.123 30.1982 104.194 31.3386C103.266 32.4571 102.186 33.2905 100.934 33.7949C102.985 34.1239 104.561 34.8915 105.641 36.1196C106.721 37.3478 107.26 38.8171 107.26 40.5936C107.26 42.8963 106.418 44.7605 104.734 46.186C103.05 47.6115 100.826 48.423 98.0626 48.5984C96.9183 48.6862 95.0831 48.73 92.5353 48.73H86.9433C86.5763 48.7081 86.3604 48.5107 86.3604 48.094ZM93.5717 24.189V32.5448H95.0831C99.0342 32.5448 100.999 31.1412 100.999 28.356C100.999 26.7769 100.524 25.7023 99.5524 25.0882C98.6024 24.4742 97.2206 24.1671 95.4285 24.1671H93.5717V24.189ZM93.5717 35.9222V45.5939H95.3638C97.7172 45.5939 99.5308 45.221 100.826 44.4535C102.122 43.6859 102.769 42.3919 102.769 40.5058C102.769 37.4574 100.654 35.9222 96.4433 35.9222H93.5717Z"
        fill="#2C3336"
      />
      <path
        d="M118.876 49.1919C115.703 49.1919 113.371 48.2488 111.903 46.3627C110.413 44.4767 109.679 41.9984 109.679 38.8842C109.679 35.7919 110.456 33.226 112.032 31.1864C113.608 29.1468 115.875 28.1379 118.833 28.1379C120.301 28.1379 121.554 28.423 122.612 28.9713C123.67 29.5196 124.468 30.2872 125.03 31.2302C126.088 33.0725 126.628 35.1998 126.628 37.6122C126.628 38.7526 126.584 39.3667 126.476 39.4764C126.368 39.586 126.174 39.6299 125.872 39.6299H114.126C114.256 41.5817 114.731 43.095 115.551 44.1477C116.372 45.2223 117.775 45.7487 119.762 45.7487C121.23 45.7487 122.741 45.3978 124.274 44.696C124.641 44.4986 124.922 44.6082 125.073 45.0249L125.829 46.8891C125.958 47.2181 125.85 47.4812 125.462 47.7005C123.713 48.7094 121.51 49.1919 118.876 49.1919ZM114.105 36.6472H122.417C122.46 33.2698 121.165 31.5811 118.509 31.5811C116.933 31.5811 115.832 32.0855 115.163 33.0944C114.515 34.1032 114.17 35.2875 114.105 36.6472Z"
        fill="#2C3336"
      />
      <path
        d="M140.944 36.6473V35.2218C140.944 33.2918 140.404 32.0856 139.346 31.6031C138.849 31.3838 138.115 31.2741 137.144 31.2741C135.33 31.2741 134.229 32.1514 133.862 33.884C133.776 34.3006 133.581 34.498 133.257 34.498H130.688C130.256 34.498 130.083 34.3006 130.127 33.884C130.235 31.9101 131.012 30.4627 132.48 29.5416C133.948 28.6205 135.805 28.1599 138.072 28.1599C142.844 28.1599 145.219 30.375 145.219 34.827V45.5075H146.946C147.356 45.5075 147.551 45.7049 147.551 46.1216V48.1173C147.551 48.534 147.356 48.7314 146.946 48.7314H142.239C141.894 48.7314 141.699 48.534 141.635 48.1173L141.354 46.2531C139.907 48.2269 137.835 49.2139 135.114 49.2139C133.408 49.2139 132.005 48.7533 130.882 47.8541C129.76 46.9549 129.198 45.4856 129.198 43.4898C129.198 40.8581 130.278 39.0597 132.437 38.0947C134.229 37.2614 136.345 36.8008 138.828 36.7131C139.368 36.6911 140.058 36.6692 140.944 36.6473ZM141.03 42.5687V39.4325C138.461 39.4325 136.906 39.5641 136.323 39.8054C135.74 40.0466 135.222 40.2879 134.769 40.5291C133.905 40.9897 133.495 41.823 133.495 43.0512C133.495 44.9153 134.553 45.8584 136.647 45.8584C138.029 45.8584 139.108 45.5294 139.886 44.8715C140.641 44.2135 141.03 43.4459 141.03 42.5687Z"
        fill="#2C3336"
      />
      <path
        d="M169.16 45.5073H170.909C171.319 45.5073 171.514 45.7047 171.514 46.1214V48.1171C171.514 48.5338 171.319 48.7312 170.909 48.7312H166.44C166.116 48.7312 165.922 48.5338 165.835 48.1171L165.468 45.8363C163.762 48.0733 161.517 49.2137 158.753 49.2137C154.435 49.2137 152.276 46.9109 152.276 42.3054V31.9539H150.16C149.75 31.9539 149.556 31.7565 149.556 31.3398V29.2125C149.556 28.7958 149.75 28.5984 150.16 28.5984H156.162C156.573 28.5984 156.767 28.7958 156.767 29.2125V41.0772C156.767 42.7001 156.983 43.8625 157.393 44.5424C157.803 45.2222 158.689 45.5731 160.027 45.5731C161.366 45.5731 162.489 45.1564 163.374 44.345C164.259 43.5335 164.691 42.6124 164.691 41.5817V31.91H162.165C161.755 31.91 161.56 31.7126 161.56 31.2959V29.2125C161.56 28.7958 161.755 28.5984 162.165 28.5984H168.556C168.966 28.5984 169.16 28.7958 169.16 29.2125V45.5073Z"
        fill="#2C3336"
      />
      <path
        d="M180.668 31.9314V42.1732C180.668 43.4453 180.819 44.3664 181.143 44.9366C181.467 45.5068 182.072 45.7919 182.935 45.7919C183.821 45.7919 184.663 45.5945 185.505 45.2217C185.807 45.112 186.001 45.2217 186.152 45.5506L186.865 47.4148C186.886 47.5025 186.908 47.6122 186.908 47.7657C186.908 47.9192 186.778 48.0727 186.498 48.2262C185.224 48.8842 183.691 49.1912 181.942 49.1912C179.567 49.1912 178.013 48.5771 177.257 47.3271C176.717 46.5156 176.415 45.7261 176.329 44.9804C176.221 43.9497 176.177 42.875 176.177 41.7346V31.9533H173.889C173.479 31.9533 173.284 31.7559 173.284 31.3392V29.2119C173.284 28.7952 173.479 28.5978 173.889 28.5978H176.177V23.115C176.177 22.6984 176.372 22.501 176.782 22.501H180.064C180.474 22.501 180.668 22.6984 180.668 23.115V28.5978H184.706C185.116 28.5978 185.31 28.7952 185.31 29.2119V31.3173C185.31 31.734 185.116 31.9314 184.706 31.9314H180.668Z"
        fill="#2C3336"
      />
      <path
        d="M195.718 45.507H198.244C198.654 45.507 198.849 45.7044 198.849 46.1211V48.1168C198.849 48.5335 198.654 48.7309 198.244 48.7309H188.723C188.312 48.7309 188.118 48.5335 188.118 48.1168V46.0991C188.118 45.6824 188.312 45.4851 188.723 45.4851H191.249V31.9316H189.09C188.68 31.9316 188.485 31.7342 188.485 31.3175V29.2121C188.485 28.7954 188.68 28.598 189.09 28.598H195.135C195.545 28.598 195.74 28.7954 195.74 29.2121V45.507H195.718ZM190.882 25.0013C190.385 24.453 190.126 23.7732 190.126 22.9398C190.126 22.1064 190.385 21.4046 190.903 20.8563C191.422 20.3081 192.112 20.0229 192.976 20.0229C193.84 20.0229 194.531 20.3081 195.049 20.8563C195.567 21.4046 195.826 22.1064 195.826 22.9398C195.826 23.7732 195.567 24.475 195.027 25.0013C194.487 25.5496 193.797 25.8128 192.933 25.8128C192.069 25.8128 191.378 25.5277 190.882 25.0013Z"
        fill="#2C3336"
      />
      <path
        d="M207.572 31.8876V45.4849H211.804C212.214 45.4849 212.409 45.6823 212.409 46.099V48.0947C212.409 48.5114 212.214 48.7088 211.804 48.7088H200.62C200.21 48.7088 200.016 48.5114 200.016 48.0947V46.099C200.016 45.6823 200.21 45.4849 200.62 45.4849H203.103V31.8876H200.62C200.21 31.8876 200.016 31.6902 200.016 31.2735V29.212C200.016 28.7953 200.21 28.5979 200.62 28.5979H203.103V24.6503C203.103 21.4045 204.096 19.321 206.104 18.4437C206.925 18.0709 208.069 17.8735 209.494 17.8735C210.919 17.8735 212.409 18.2025 213.899 18.8824C214.136 18.992 214.266 19.1236 214.266 19.2991C214.266 19.4526 214.244 19.5842 214.222 19.6719L213.618 21.7115C213.488 22.0405 213.251 22.1282 212.949 21.9966C211.891 21.5141 210.876 21.2729 209.904 21.2729C208.933 21.2729 208.328 21.6018 208.026 22.2378C207.724 22.8738 207.594 24.0143 207.594 25.6591V28.6198H212.43C212.841 28.6198 213.035 28.8172 213.035 29.2339V31.2954C213.035 31.7121 212.841 31.9095 212.43 31.9095H207.572V31.8876Z"
        fill="#2C3336"
      />
      <path
        d="M233.936 45.5073H235.684C236.095 45.5073 236.289 45.7047 236.289 46.1214V48.1171C236.289 48.5338 236.095 48.7312 235.684 48.7312H231.215C230.891 48.7312 230.697 48.5338 230.611 48.1171L230.244 45.8363C228.538 48.0733 226.292 49.2137 223.529 49.2137C219.211 49.2137 217.051 46.9109 217.051 42.3054V31.9539H214.936C214.525 31.9539 214.331 31.7565 214.331 31.3398V29.2125C214.331 28.7958 214.525 28.5984 214.936 28.5984H220.938C221.348 28.5984 221.542 28.7958 221.542 29.2125V41.0772C221.542 42.7001 221.758 43.8625 222.169 44.5424C222.579 45.2222 223.464 45.5731 224.803 45.5731C226.141 45.5731 227.264 45.1564 228.149 44.345C229.034 43.5335 229.466 42.6124 229.466 41.5817V31.91H226.94C226.53 31.91 226.336 31.7126 226.336 31.2959V29.2125C226.336 28.7958 226.53 28.5984 226.94 28.5984H233.331C233.741 28.5984 233.936 28.7958 233.936 29.2125V45.5073Z"
        fill="#2C3336"
      />
      <path
        d="M245.723 19.0147V45.4856H248.249C248.659 45.4856 248.854 45.683 248.854 46.0997V48.0955C248.854 48.5121 248.659 48.7095 248.249 48.7095H238.728C238.317 48.7095 238.123 48.5121 238.123 48.0955V46.0997C238.123 45.683 238.317 45.4856 238.728 45.4856H241.254V21.7342H239.095C238.684 21.7342 238.49 21.5368 238.49 21.1201V19.0147C238.49 18.598 238.684 18.4006 239.095 18.4006H245.14C245.529 18.4226 245.723 18.6199 245.723 19.0147Z"
        fill="#2C3336"
      />
      <path
        d="M276.274 48.7083H273.64C273.23 48.7083 272.971 48.5109 272.798 48.1381L265.047 28.1149V45.5064H267.573C267.984 45.5064 268.178 45.7038 268.178 46.1204V48.1162C268.178 48.5329 267.984 48.7303 267.573 48.7303H259.455C259.045 48.7303 258.851 48.5329 258.851 48.1162V46.0985C258.851 45.6818 259.045 45.4844 259.455 45.4844H261.571V24.4086H259.455C259.045 24.4086 258.851 24.2112 258.851 23.7945V21.733C258.851 21.3163 259.045 21.1189 259.455 21.1189H266.451C266.882 21.1189 267.142 21.3163 267.249 21.6891L275.476 43.4887L283.918 21.6891C284.026 21.3163 284.285 21.1189 284.717 21.1189H291.388C291.798 21.1189 291.993 21.3163 291.993 21.733V23.7945C291.993 24.2112 291.798 24.4086 291.388 24.4086H289.272V45.4844H291.388C291.798 45.4844 291.993 45.6818 291.993 46.0985V48.0942C291.993 48.5109 291.798 48.7083 291.388 48.7083H282.601C282.19 48.7083 281.996 48.5109 281.996 48.0942V46.0985C281.996 45.6818 282.19 45.4844 282.601 45.4844H284.997V28.1369L277.117 48.1162C276.944 48.5109 276.663 48.7083 276.274 48.7083Z"
        fill="#2C3336"
      />
      <path
        d="M301.817 45.507H304.343C304.753 45.507 304.948 45.7044 304.948 46.1211V48.1168C304.948 48.5335 304.753 48.7309 304.343 48.7309H294.821C294.411 48.7309 294.217 48.5335 294.217 48.1168V46.0991C294.217 45.6824 294.411 45.4851 294.821 45.4851H297.347V31.9316H295.188C294.778 31.9316 294.584 31.7342 294.584 31.3175V29.2121C294.584 28.7954 294.778 28.598 295.188 28.598H301.234C301.644 28.598 301.838 28.7954 301.838 29.2121V45.507H301.817ZM296.98 25.0013C296.484 24.453 296.225 23.7732 296.225 22.9398C296.225 22.1064 296.484 21.4046 297.002 20.8563C297.52 20.3081 298.211 20.0229 299.075 20.0229C299.938 20.0229 300.629 20.3081 301.148 20.8563C301.666 21.4046 301.925 22.1064 301.925 22.9398C301.925 23.7732 301.666 24.475 301.126 25.0013C300.586 25.5496 299.895 25.8128 299.032 25.8128C298.168 25.8128 297.477 25.5277 296.98 25.0013Z"
        fill="#2C3336"
      />
      <path
        d="M306.329 31.3618V29.2126C306.329 28.7959 306.523 28.5985 306.934 28.5985H312.375C312.72 28.5985 312.936 28.7959 313.022 29.2126L313.389 31.5592C314.879 29.2784 317.017 28.1379 319.78 28.1379C323.904 28.1379 325.977 30.4407 325.977 35.0463V45.4855H328.287C328.697 45.4855 328.892 45.6829 328.892 46.0996V48.0953C328.892 48.512 328.697 48.7094 328.287 48.7094H319.564C319.154 48.7094 318.96 48.512 318.96 48.0953V46.0996C318.96 45.6829 319.154 45.4855 319.564 45.4855H321.486V37.0859C321.486 35.3752 321.27 34.1251 320.838 33.3576C320.406 32.568 319.543 32.1733 318.204 32.1733C316.866 32.1733 315.764 32.59 314.879 33.4014C313.994 34.2129 313.562 35.134 313.562 36.1647V45.5074H316.045C316.455 45.5074 316.65 45.7048 316.65 46.1215V48.1172C316.65 48.5339 316.455 48.7313 316.045 48.7313H306.977C306.567 48.7313 306.372 48.5339 306.372 48.1172V46.0996C306.372 45.6829 306.567 45.4855 306.977 45.4855H309.093V31.932H306.934C306.523 31.932 306.329 31.7566 306.329 31.3618Z"
        fill="#2C3336"
      />
      <path
        d="M349.272 45.5076H351C351.41 45.5076 351.604 45.705 351.604 46.1216V48.1174C351.604 48.5341 351.41 48.7315 351 48.7315H346.487C346.142 48.7315 345.947 48.5341 345.883 48.1174L345.559 45.7269C344.199 48.0516 342.018 49.2139 339.038 49.2139C336.426 49.2139 334.418 48.3806 332.993 46.7138C331.589 45.047 330.877 42.4591 330.877 38.9501C330.877 35.4412 331.697 32.7656 333.338 30.9233C334.979 29.0811 337.095 28.16 339.664 28.16C340.83 28.16 341.867 28.3793 342.774 28.8399C343.68 29.2785 344.35 29.8268 344.781 30.4409V21.778H342.666C342.255 21.778 342.061 21.5807 342.061 21.164V19.0147C342.061 18.598 342.255 18.4006 342.666 18.4006H348.668C349.078 18.4006 349.272 18.598 349.272 19.0147V45.5076ZM344.781 41.0775V33.9279C344.436 33.3796 343.831 32.8971 342.968 32.4147C342.104 31.9541 341.219 31.7348 340.355 31.7348C337.052 31.7348 335.389 34.0156 335.389 38.5554C335.389 42.0424 336.145 44.2356 337.635 45.0909C338.283 45.4637 339.146 45.6611 340.269 45.6611C341.392 45.6611 342.428 45.2444 343.378 44.411C344.306 43.5557 344.781 42.4591 344.781 41.0775Z"
        fill="#2C3336"
      />
      <path
        d="M357.068 29.9363C358.428 28.7521 360.155 28.1599 362.228 28.1599C364.301 28.1599 366.114 28.4012 367.626 28.8836C367.993 28.9933 368.187 29.2345 368.187 29.6074V33.9059C368.187 34.3226 367.993 34.52 367.582 34.52H365.272C364.84 34.52 364.668 34.3226 364.754 33.9059L364.948 31.8444C364.15 31.4057 363.264 31.1864 362.314 31.1864C360.177 31.1864 359.119 31.954 359.119 33.4673C359.119 34.2568 359.464 34.8709 360.155 35.3314C360.846 35.792 361.688 36.1648 362.681 36.4499C363.675 36.735 364.668 37.064 365.661 37.4149C366.654 37.7877 367.496 38.4018 368.209 39.279C368.9 40.1563 369.245 41.2748 369.245 42.6345C369.245 44.696 368.532 46.297 367.129 47.4374C365.726 48.5779 363.739 49.17 361.17 49.17C359.378 49.17 357.5 48.9068 355.535 48.3585C355.189 48.2489 355.017 47.9857 355.017 47.591V43.2924C355.017 42.8757 355.211 42.6784 355.621 42.6784H357.931C358.342 42.6784 358.514 42.8757 358.493 43.2924L358.363 45.3978C359.076 45.9461 360.112 46.2093 361.451 46.2093C363.826 46.2093 365.013 45.354 365.013 43.6433C365.013 42.788 364.668 42.0862 363.998 41.5818C363.329 41.0774 362.487 40.7046 361.515 40.4195C360.544 40.1563 359.572 39.8492 358.579 39.4983C357.586 39.1694 356.765 38.5553 356.075 37.7219C355.405 36.8666 355.06 35.7481 355.06 34.3664C355.017 32.6339 355.708 31.1426 357.068 29.9363Z"
        fill="#2C3336"
      />
    </svg>
  </Link>
);

export default FullLogoAlt;
